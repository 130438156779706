<template>
  <v-container v-if="tooltip" class="text-uppercase pa-0 ma-0">
    <!-- CONTAINER -->
    <v-row no-gutters>
      <v-col class="2">
        <span >Convoglio: </span>
      </v-col>
      <v-col class="10">
        <span class="font-weight-bold ">{{ event.convoglio.nome }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters >
      <v-col>
        <span>STATO:</span>
        <span class="font-weight-bold"> {{ event.status }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span>ETA:</span>
        <span class="font-weight-bold">{{ event.convoglio.eta | formatDateTime }}</span>
      </v-col>
      <v-col>
        <span>ATA:</span>
        <span class="font-weight-bold">{{ event.convoglio.ata | formatDateTime }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span>ETD:</span>
        <span class="font-weight-bold">{{ event.convoglio.etd | formatDateTime }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span>t. occ.:</span>
        <span class="font-weight-bold">{{ event.inizio | formatDateTimeNoYear }} - {{ event.fine | formatDateTimeNoYear }}</span>
      </v-col>
      <v-col>
        <span>t. lav.:</span>
        <span class="font-weight-bold">{{ event.convoglio.tempoLavorazione }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span>viaggi in (prov.):</span>
      </v-col>
      <v-col v-for="viaggio in viaggiArrivo" :key="viaggio.id">
        <span class="font-weight-bold">{{ viaggio.nome }} - {{ viaggio.nodoPartenza.codice }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span>viaggi out (dest.):</span>
      </v-col>
      <v-col v-for="viaggio in viaggiPartenza" :key="viaggio.id">
        <span class="font-weight-bold">{{ viaggio.nome }} - {{ viaggio.nodoArrivo.codice }}</span>
      </v-col>
    </v-row>

    <!-- DIVIDER -->
    <v-row no-gutters class="pt-3">
      <v-divider :thickness="20" class="border-opacity-75" color="error"></v-divider>
    </v-row>

    <!-- CARRO -->
    <v-row no-gutters class="pt-3">
      <v-col class="2">
        <span >Carro: </span>
      </v-col>
      <v-col>
        <span class="font-weight-bold ">{{ carro.visitaCarro.targa }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span>viaggi in (prov.):</span>
      </v-col>
      <v-col v-for="carroViaggio in carroViaggiArrivo" :key="carroViaggio.id">
        <span class="font-weight-bold">{{ carroViaggio.nome }} - {{ carroViaggio.nodoPartenza.codice }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span>viaggi out (dest.):</span>
      </v-col>
      <v-col v-for="carroViaggio in carroViaggiPartenza" :key="carroViaggio.id">
        <<span class="font-weight-bold">{{ carroViaggio.nome }} - {{ carroViaggio.nodoArrivo.codice }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import railsMixins from "../rails/mixins/railsMixins";
import moment from "moment";

export default {
  name: "WagonInfoPanel",
  mixins: [railsMixins],
  data() {
    return {
      event: null,
      viaggiArrivo: [],
      viaggiPartenza: [],
      carroViaggiArrivo: [],
      carroViaggiPartenza: [],
    };
  },
  props: {
    carro: {
      type: Object,
      required: true,
    },
    convoglio: {
      type: Object | null,
      required: false,
    },
    tooltip: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  created() {
    if (this.convoglio) {
      this.setEvent(this.convoglio);
      //viaggi convoglio
      if (this.convoglio.listCarri && this.convoglio.listCarri.length > 0) {
        this.convoglio.listCarri.forEach((c) => {
          if (c.viaggioIn && !this.viaggiArrivo.find((v) => v.id === c.viaggioIn.id)) {
            this.viaggiArrivo.push(c.viaggioIn);
          }
          if (c.viaggioOut && !this.viaggiPartenza.find((v) => v.id === c.viaggioOut.id)) {
            this.viaggiPartenza.push(c.viaggioOut);
          }
        });
      }
    }

    //viaggi carro
    if (this.carro.viaggioIn && !this.carroViaggiArrivo.find((v) => v.id === this.carro.viaggioIn.id)) {
      this.carroViaggiArrivo.push(this.carro.viaggioIn);
    }
    if (this.carro.viaggioOut && !this.viaggiPartenza.find((v) => v.id === this.carro.viaggioOut.id)) {
      this.carroViaggiPartenza.push(this.carro.viaggioOut);
    }
  },
  watch: {
    convoglio: function (newConvoglio) {
      if (newConvoglio) {
        this.setEvent(newConvoglio);
      }
      console.log("convoy event", this.event);
    },
  },
  methods: {
    setEvent(newConvoglio) {
      let start = newConvoglio.eta ? moment(newConvoglio.eta) : moment(newConvoglio.etd);
      let end = newConvoglio.etd ? moment(newConvoglio.etd) : moment(newConvoglio.eta);

      this.event = {
        name: newConvoglio.nome ? newConvoglio.nome : `Convoglio ${newConvoglio.id}`,
        convoglio: newConvoglio,
        start: start.format("YYYY-MM-DD HH:mm"),
        end: end.format("YYYY-MM-DD HH:mm"),
      };

      const status = newConvoglio.stato ? newConvoglio.stato.toLowerCase() : "creato";
      this.event.color = this.$config.rails.convoyStates[status].color;
      this.event.status = this.$config.rails.convoyStates[status].status;
    },
  },
};
</script>
