<template>
    <v-card 
        :class="`uti-detail-card ${ utiCarro.remainOnBoardScarico ? 'uti-rob' : '' }`" 
        :style="`background:${getUtiStateColor(utiCarro)};`"
    >
        <v-card-text>
            <v-icon 
                v-if="pericoloso"
                class="uti-detail-danger-icon" 
            >
                mdi-alert
            </v-icon>
            <b>{{ codice }}</b><br/>
            stato: <b>{{ stato }}</b><br/><br/>
            <v-divider></v-divider>
            <v-icon>mdi-information</v-icon><br/>
              <span><b>tipo:</b> {{ tipo }}</span><br/><br/>
            <v-divider></v-divider>
            <v-icon>mdi-tape-measure</v-icon><br/>
            <span><b>peso:</b> {{ peso }} kg</span><br/>
            <span><b>dimensioni:</b> {{ lunghezza }} ft</span><br/>
            <span v-if="numeroColli"><b>colli:</b> {{numeroColli}}</span>
            <!-- <div v-if="!utiCarro.remainOnBoardScarico">
                <div
                    v-for="rAction in getUtiAvailableActions(utiCarro)"
                    :key="rAction.status"
                    class="uti-detail-actions"
                >
                    <v-btn     
                        :color="rAction.color"
                        x-small
                        @click="changeUtiStatus(utiCarro, rAction.action)"
                    >
                        {{ rAction.action | capitalize }}
                    </v-btn>
                </div>
            </div> -->
        </v-card-text>
    </v-card>  
</template>

<script>
import convoyVisualizationMixins from './mixins/convoyVisualizationMixins'

export default {
    name: "wagonDialogUtiDetails",  
    mixins: [convoyVisualizationMixins],
    props: {
        utiCarro: {
            type: Object,
            required: true
        },
    },
    computed: {
        codice() {
            return this.getUtiCode(this.utiCarro);
        },
        tipo() {
            return this.getUtiType(this.utiCarro);
        },
        peso() {
            return this.getUtiWeight(this.utiCarro);
        },
        lunghezza() {
            return this.getUtiLength(this.utiCarro);
        },
        pericoloso() {
            return this.getUtiDangerousFlag(this.utiCarro);
        },
        stato() {
            const statusObj = this.$config.rails.utiWagonStates[this.utiCarro.tipoMovimento.toLowerCase()][this.utiCarro.stato.toLowerCase()];
            return statusObj ? statusObj.status : '';
        },
        numeroColli() {
            return this.getUtiNumber(this.utiCarro);
        }
    }
}
</script>

<style scoped>
.uti-detail-card{
    background: rgb(204, 191, 191)
}
.uti-rob{
    background-image: repeating-linear-gradient(-45deg,
      #ffffff,
      #ffffff 10px,
      rgb(196, 196, 192) 10px,
      rgb(196, 196, 192) 20px) !important;
}
.uti-detail-danger-icon{
    color:red;
}
.uti-detail-actions{
    width: 100%;
    text-align: right;
}
</style>