<template>
  <div class="grey lighten-5">
    <v-row class="rail-row" no-gutters :id="`binario-${binario.id}`" v-on:dblclick="selectingRail">
      <v-col cols="12">
        <div class="rail-label">B. {{ binario.numero }}</div>
        <div
          class="rail"
          :selected="selectedAttributeValue"
          :style="`width:${(100 * binario.lunghezza) / maxLengthFactor}%;min-width:${(600 * binario.lunghezza) / maxLengthFactor}px;`"
        ></div>
        <wagon
          v-for="(carroConvoglio, index) in carriConvoglio"
          :active="index === activeIndex" 
          :index="index"
          :key="carroConvoglio.id"
          :carroConvoglio="carroConvoglio"
          :maxLengthFactor="maxLengthFactor"
          :utiSearchParam="utiSearchParam"
          :wagonSearchParam="wagonSearchParam"
          :plannedShowing="plannedShowing"
          :featuresShowing="featuresShowing"
          :yardShowing="yardShowing"
          :convoglioSel="convoglioSel"
          :numBinario="binario.numero"
          @set-active="activeIndex = $event"
        />
        <div class="rail-distance-cntr" :style="`width:${(100 * binario.lunghezza) / maxLengthFactor}%;min-width:${(600 * binario.lunghezza) / maxLengthFactor}px;`">
          <div
            v-for="(distanceMarker, index) in getRailIntervalList(binario, lengthInterval)"
            :key="index"
            class="rail-distance"
            :style="`width:${distanceMarker.dm}%;font-size:${(((3 * maxLengthFactor) / 100) * lengthInterval) / maxLengthFactor}rem;`"
          >
            <span>
              {{ distanceMarker.length }}
            </span>
          </div>
        </div>
        <!-- <div 
          v-for="gru in binario.listaGru" 
          :key="gru.id" class="crane" 
          :style="`width:${100*20/maxLengthFactor}%;left:${100*gru.posizioneBinario/maxLengthFactor}%;border:${100*20/maxLengthFactor}px solid rgba(170,170,170,0.5);`"
        >
        </div> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import multiParkManagementMixins from "../../mixins/MultiParkManagementMixins";
import convoyVisualizationMixins from "../../components/rails/mixins/convoyVisualizationMixins";
import wagon from "./Wagon";

export default {
  name: "railSpatialVisualization",
  components: {
    wagon,
  },
  props: {
    binario: {
      type: Object,
      required: true,
    },
    maxLengthFactor: {
      type: Number,
      default: 200,
    },
    lengthInterval: {
      type: Number,
      default: 10,
    },
    convogli: {
      type: Array,
      default: () => [],
    },
    convoglioSel: {
      type: Object,
      required: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    utiSearchParam: {
      type: String,
      default: "",
    },
    wagonSearchParam: {
      type: String,
      default: "",
    },
    plannedShowing: {
      type: Boolean,
      default: false,
    },
    featuresShowing: {
      type: Boolean,
      default: false,
    },
    yardShowing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mixins: [multiParkManagementMixins, convoyVisualizationMixins],
  mounted() {},
  computed: {
    maxRailLength() {
      const maxLengthRail = _.maxBy(this.binari, (b) => {
        return b.lunghezza;
      });
      return maxLengthRail && maxLengthRail.lunghezza ? maxLengthRail.lunghezza : 0;
    },
    carriConvoglio() {
      let carriConvoglio = [];
      this.convogli.forEach((convoglio) => {
        if (convoglio.listCarri) {
          let pos = convoglio.posizioneBinario;
          convoglio.listCarri = this._.orderBy(convoglio.listCarri, [(cc) => cc.posizione]);
          convoglio.listCarri.forEach((cc) => {
            cc.dialog = false;
            cc.posizioneBinario = pos;
            cc.idConvoglio = convoglio.id;
            pos += this.getCarroLength(cc);
            carriConvoglio.push(cc);
          });
        }
      });
      return carriConvoglio;
    },
    selectedAttributeValue() {
      if (this.selected) {
        return true;
      }
      return false;
    },
  },
  methods: {
    selectingRail() {
      this.$eventBus.$emit("selectingRail", this.binario.id);
    },
    getRailIntervalList(binario, lengthInterval) {
      let lRes = binario.lunghezza;
      const resList = [];
      for (let i = 0; i < Math.round(binario.lunghezza / lengthInterval); i++) {
        if (lRes > lengthInterval) {
          resList.push({ dm: (100 * lengthInterval) / binario.lunghezza, length: lengthInterval * (i + 1) });
        } else {
          resList.push({ dm: (100 * lRes) / binario.lunghezza, length: binario.lunghezza });
        }
        lRes -= lengthInterval;
      }
      return resList;
    },
  },
};
</script>

<style scoped>
.rail-row {
  z-index: 1;
  height: 75px;
  position: relative;
}
/* .rail-row:hover{
  background:rgba(225, 225 ,225, 0.8);
} */
.rail {
  z-index: 0;
  position: absolute;
  height: 40px;
  margin-top: 20px;
  border: 2px solid #aaaaaa;
}
.rail[selected="selected"] {
  background: #d3d3d3;
}
.rail-distance-cntr {
  z-index: 1;
  position: absolute;
  margin-top: 25px;
}
.rail-distance {
  height: 55px;
  margin-top: -20px;
  float: left;
  border-right: 1px solid #aaaaaa;
  text-align: right;
  color: rgb(99, 99, 99);
  padding-right: 1px;
}
.rail-label {
  position: absolute;
  left: 0px;
  font-size: 0.9rem;
}
/* .crane{
  z-index:1;
  position:absolute;
  height:60px;
  margin-top:15px;  
} */
</style>
