<template>
<div>
    <v-card v-if="dialog" class="dialog">
        <v-card-title>
            Targa: {{ licensePlate }}        
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="dialog-text">
            <v-btn medium block color="primary" @click="closeInput()" class="py-3 my-3">Chiudi</v-btn>
            <v-expansion-panels accordion>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-icon class="accordion-icon">mdi-upload</v-icon>
                        <span>Export</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div
                            v-for="utiCarro in orderedExportUties"
                            :key="utiCarro.id"
                        >
                            <wagon-dialog-uti-details :utiCarro="utiCarro" />
                            <br/>
                        </div>
                    </v-expansion-panel-content>  
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-icon class="accordion-icon">mdi-download</v-icon>
                        <span>Import</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div
                            v-for="utiCarro in orderedImportUties"
                            :key="utiCarro.id"
                        >
                            <wagon-dialog-uti-details :utiCarro="utiCarro" />
                            <br/>
                        </div>
                    </v-expansion-panel-content>  
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-icon class="accordion-icon">mdi-information</v-icon>
                        <span>Info</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <span><b>TARGA:</b> {{ licensePlate }}</span><br/>
                        <span><b>ISO:</b> {{ iso }}</span><br/>
                        <span><b>IMO:</b> {{ imo }}</span>
                    </v-expansion-panel-content>  
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-icon class="accordion-icon">mdi-tape-measure</v-icon>
                        <span>Misure</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <span><b>lunghezza:</b> {{ length }} m </span>
                        <span><b>posizione binario:</b> {{ posizioneBinario }} m</span>
                    </v-expansion-panel-content>  
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-icon class="accordion-icon">mdi-train</v-icon>
                        <span>Viaggi</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <span v-if="carroConvoglio.viaggioIn">
                            <b>viaggio in ({{(viewNomeViaggio() ? carroConvoglio.viaggioIn.nome : carroConvoglio.viaggioIn.codice)}}):</b> {{ carroConvoglio.viaggioIn.eta }} da {{ carroConvoglio.viaggioIn.nodoPartenza.descrizione }}
                        </span><br/> 
                        <span v-if="carroConvoglio.viaggioOut">
                            <b>viaggio out ({{(viewNomeViaggio() ? carroConvoglio.viaggioOut.nome : carroConvoglio.viaggioOut.codice)}}):</b> {{ carroConvoglio.viaggioOut.etd }} a {{ carroConvoglio.viaggioOut.nodoArrivo.descrizione }}
                        </span>
                    </v-expansion-panel-content>  
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
    </v-card>
</div>    
</template>

<script>
import convoyVisualizationMixins from './mixins/convoyVisualizationMixins';
import wagonDialogUtiDetails from './WagonDialogUtiDetails';
import railsMixins from './mixins/railsMixins';


export default {
    
    name: "wagonDialog", 
    components: {
        wagonDialogUtiDetails
    },
    props: {
        carroConvoglio: {
            type: Object | null,
            required: true
        },
        dialog: {
            type: Boolean,
            default: false
        },
        plannedShowing: {
          type: Boolean,
          default: false
        }
    },
    mixins: [convoyVisualizationMixins,railsMixins],
    data() {
        return {
        };
    },
    computed: {
        orderedImportUties() {
            if(!this.plannedShowing) {
                return this.getRTOrderedImportUties(this.carroConvoglio);          
            }
            return this.getOrderedImportUties(this.carroConvoglio);
        },
        orderedExportUties() {
            if(!this.plannedShowing) {
                return this.getRTOrderedExportUties(this.carroConvoglio);
            }
            return this.getOrderedExportUties(this.carroConvoglio);
        },
        utiCount() {
            if(!this.plannedShowing){
                return this.getRTCarroUtiCount(this.carroConvoglio);
            }
            return this.getCarroUtiCount(this.carroConvoglio);
        },
        iso() {
            return this.getCarroIso(this.carroConvoglio);
        },
        imo() {
            return this.getCarroIso(this.carroConvoglio);
        },
        length() {
            return this.getCarroLength(this.carroConvoglio);
        },
        licensePlate() {
            return this.getCarroLicensePlate(this.carroConvoglio);
        },
        posizioneBinario() {
            if(!isNaN(this.carroConvoglio.posizioneBinario)){
                return parseFloat(this.carroConvoglio.posizioneBinario).toFixed(2);
            }
            return 0;
        }
    },
    methods: {
        closeInput () {
            this.dialog = !this.dialog;
            this.$emit('close', this.dialog);
        }
    }
}
</script>

<style scoped>
.dialog {
    width:300px;
    height: 100%;
    position:fixed;
    z-index: 20000;
    right:0%;
    top:0%
}
.dialog-text {
    height: 100%;
    overflow: auto;
}
.accordion-icon {
    display: inline;
}
</style>