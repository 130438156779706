<template>
<div>
    <v-card v-if="dialog" class="dialog">
        <v-card-text class="dialog-text">
            <convoyInfoPanel v-if="event" :event="event" />
        </v-card-text>
    </v-card>
</div>    
</template>

<script>
import convoyVisualizationMixins from '../mixins/convoyVisualizationMixins';
import convoyInfoPanel from '../../misc/ConvoyInfoPanel'
import moment from 'moment';

export default {
    name: "convoyDialog", 
    components: {
       convoyInfoPanel, 
    },
    props: {
        convoglio: {
            type: Object | null,
            required: true
        },
        dialog: {
            type: Boolean,
            default: false
        }
    },
    mixins: [convoyVisualizationMixins],
    data() {
        return {
            event: null
        };
    },
    watch: {
        convoglio: function(newConvoglio){
            if(newConvoglio){
                let start = newConvoglio.eta ? moment(newConvoglio.eta) : moment(newConvoglio.etd);
                let end = newConvoglio.etd ? moment(newConvoglio.etd) : moment(newConvoglio.eta);

                this.event = {
                    name: newConvoglio.nome ? newConvoglio.nome : `Convoglio ${newConvoglio.id}`,
                    convoglio: newConvoglio,
                    start: start.format('YYYY-MM-DD HH:mm'),
                    end: end.format('YYYY-MM-DD HH:mm'),
                };

                let now = moment();
                const status = newConvoglio.stato ? newConvoglio.stato.toLowerCase() : 'creato';
                this.event.color = this.$config.rails.convoyStates[status].color;
                this.event.status = this.$config.rails.convoyStates[status].status;
            } else {
                this.event = null;
            }   
            console.log("convoy event", this.event);        
        }
    },
    methods: {
    }
}
</script>

<style scoped>
.dialog {
    width:300px;
    position:fixed;
    z-index: 20000;
    right:0%;
    top:0%
}
.dialog-text {
    max-height: 325px;
    overflow: auto;
}
.accordion-icon {
    display: inline;
}
</style>