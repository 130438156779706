import formatMixins from '../../../mixins/FormatsMixins';
import railsMixins from './railsMixins';


export default {
    data() {
        return {
            zoom: 1,
            tapEvCache: [],
            carroConvoglioSel: {},
            dragOptions: {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            },
        };
    },
    mixins: [railsMixins, formatMixins],
    methods: {
        initComponent() {
            this.$eventBus.$on('wagonSelected', (carroConvoglio) => {
                this.carroConvoglioSel = carroConvoglio;
            });

            const convoyVisCntr = document.querySelector("#convoyCntr");
            let isDown = false;
            let startX, startY, scrollLeft, scrollTop;

            if (convoyVisCntr) {
                this.$utilities.dom.addHandler(convoyVisCntr, ['wheel'], e => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (event.ctrlKey) {
                        if (this.$utilities.dom.detectMouseWheelDirection(e) === 'up') {
                            this.zoom += this.zoom < 3 ? 0.1 : 0;
                        } else {
                            this.zoom -= this.zoom > 1 ? 0.1 : 0;
                        }
                        convoyVisCntr.setAttribute("style", `zoom:${this.zoom};transform: scale(${this.zoom});transform-origin: 0 0;-webkit-transform-origin: 0 0;-moz-transform:Scale(${this.zoom});-moz-transform-origin:0 0`);
                    }
                });

                this.$utilities.dom.addHandler(convoyVisCntr, ['pointerdown'], e => {
                    this.tapEvCache.push(e);
                });

                this.$utilities.dom.addHandler(convoyVisCntr, ['pointerup'], e => {
                    for (var i = 0; i < this.tapEvCache.length; i++) {
                        if (this.tapEvCache[i].pointerId == e.pointerId) {
                            this.tapEvCache.splice(i, 1);
                            break;
                        }
                    }
                });

                this.$utilities.dom.addHandler(convoyVisCntr, ['pointermove'], e => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (this.tapEvCache.length == 2) {
                        let curDiff = Math.abs(this.tapEvCache[0].clientX - this.tapEvCache[1].clientX);
                        if (this.tapPrevDiff > 0) {
                            if (curDiff > this.tapPrevDiff) {
                                this.railZoom += this.railZoom < 3 ? 0.1 : 0;
                            }
                            if (curDiff < this.tapPrevDiff) {
                                this.railZoom -= this.railZoom > 1 ? 0.1 : 0;
                            }
                            convoyVisCntr.setAttribute("style", `zoom:${this.zoom};-moz-transform:Scale(${this.zoom});-moz-transform-origin:0 0`);
                        }
                        this.tapPrevDiff = curDiff;
                    } else {
                        if (!isDown) return;

                        const x = e.pageX - convoyCntr.offsetLeft;
                        const walk = x - startX;
                        convoyCntr.scrollLeft = scrollLeft - walk;

                        const y = e.pageY - convoyCntr.offsetTop;
                        const walkY = y - startY;
                        convoyCntr.scrollTop = scrollTop - walkY;
                    }
                });
            }

            const convoyCntr = document.querySelector("#convoyVis");

            if (convoyCntr) {
                this.$utilities.dom.addHandler(convoyCntr, ['pointerdown'], e => {
                    isDown = true;
                    startX = e.pageX - convoyCntr.offsetLeft;
                    startY = e.pageY - convoyCntr.offsetTop;
                    scrollLeft = convoyCntr.scrollLeft;
                    scrollTop = convoyCntr.scrollTop;
                });

                this.$utilities.dom.addHandler(convoyCntr, ['pointerleave'], () => {
                    isDown = false;
                });

                this.$utilities.dom.addHandler(convoyCntr, ['pointerup'], () => {
                    isDown = false;
                });
            }
        },
        goToConvoyDashboard() {
            if (this.convoyId) {
                this.$router.push({ path: `/rails/ConvoyVisualization/${this.convoyId}` });
                return;
            }
            this.$router.push({ path: `/` });
        },
        onWagonOrderingDragEnd() {
            if (this.convoglio) {
                for (let i = 0; i < this.convoglio.listCarri.length; i++) {
                    this.convoglio.listCarri[i].posizione = i + 1;
                }
            }
        },
        getCarroUtiCount(carroConvoglio) {
            return carroConvoglio.listUti ? carroConvoglio.listUti.length : 0;
        },
        getRTCarroUtiCount(carroConvoglio) {
            if (!carroConvoglio.listUti || !carroConvoglio.listUti.length) {
                return 0;
            }
            const list = this._.find(carroConvoglio.listUti, u => u.stato.toLowerCase() === 'caricato');
            return list && list.length ? list.length : 0;
        },
        getCarroType(carroConvoglio) {
            if (carroConvoglio.visitaCarro.tipoCarro) {
                return carroConvoglio.visitaCarro.tipoCarro.codice;
            } else if (carroConvoglio.visitaCarro.carro && carroConvoglio.visitaCarro.carro.tipoCarro) {
                return carroConvoglio.visitaCarro.carro.tipoCarro.codice;
            }
            return '';
        },
        isCarroConvenzionale(carroConvoglio) {
            let tipoCarro = null;
            if (carroConvoglio.visitaCarro.tipoCarro) {
                tipoCarro = carroConvoglio.visitaCarro.tipoCarro;
            } else if (carroConvoglio.visitaCarro.carro && carroConvoglio.visitaCarro.carro.tipoCarro) {
                tipoCarro = carroConvoglio.visitaCarro.carro.tipoCarro;
            }
            return tipoCarro!=null && tipoCarro.convenzionale;
        },
        getCarroIso(carroConvoglio) {

            if (carroConvoglio.visitaCarro && carroConvoglio.visitaCarro.carro) {
                return carroConvoglio.visitaCarro.carro.iso;
            }
            return '';
        },
        getCarroImo(carroConvoglio) {
            if (carroConvoglio.visitaCarro && carroConvoglio.visitaCarro.carro) {
                return carroConvoglio.visitaCarro.carro.imo;
            }
            return '';
        },
        getCarroLength(carroConvoglio) {
            if (carroConvoglio.visitaCarro.carro) {
                return carroConvoglio.visitaCarro.carro.lunghezza ||
                    20;
            } else if (carroConvoglio.visitaCarro.tipoCarro) {
                return carroConvoglio.visitaCarro.tipoCarro.lunghezza || 20;
            }
            return 20;
        },
        getConvoyLength() {
            if (!this.convoglio) return 0;
            return this._.sumBy(this.convoglio.listCarri.filter(c => c.visitaCarro.targa!=='0'), cc => this.getCarroLength(cc));
        },
        getCarroLicensePlate(carroConvoglio) {
            if (carroConvoglio.visitaCarro) {
                return carroConvoglio.visitaCarro.targa;
            }
            return '';
        },
        // getCarroTara(carroConvoglio) {
        //     if (carroConvoglio.visitaCarro && carroConvoglio.visitaCarro.carro) {
        //         return carroConvoglio.visitaCarro.carro.tara;
        //     }
        //     return '';
        // },
        getCarroTara(carroConvoglio) {
            if (carroConvoglio.visitaCarro) {
                if (carroConvoglio.visitaCarro.carro) {
                    if (carroConvoglio.visitaCarro.carro.tara) {
                        return carroConvoglio.visitaCarro.carro.tara;
                    } else if (carroConvoglio.visitaCarro.carro.tipoCarro && carroConvoglio.visitaCarro.carro.tipoCarro.tara) {
                        return carroConvoglio.visitaCarro.carro.tipoCarro.tara;
                    }
                } 
                if (carroConvoglio.visitaCarro.tipoCarro) {
                    return carroConvoglio.visitaCarro.tipoCarro.tara;
                }
            }
            return '';
        },
        // getCarroPortataMax(carroConvoglio) {
        //     if (carroConvoglio.visitaCarro && carroConvoglio.visitaCarro.carro) {
        //         return carroConvoglio.visitaCarro.carro.portataMax;
        //     }
        //     return '';
        // },
        getCarroPortataMax(carroConvoglio) {
            if (carroConvoglio.visitaCarro) {
                if (carroConvoglio.visitaCarro.carro) {
                    if (carroConvoglio.visitaCarro.carro.portataMax) {
                        return carroConvoglio.visitaCarro.carro.portataMax;
                    } else if (carroConvoglio.visitaCarro.carro.tipoCarro && carroConvoglio.visitaCarro.carro.tipoCarro.portataMax) {
                        return carroConvoglio.visitaCarro.carro.tipoCarro.portataMax;
                    }                
                }
                if (carroConvoglio.visitaCarro.tipoCarro) {
                    return carroConvoglio.visitaCarro.tipoCarro.portataMax;
                }
            }
            return '';
        },
        getSumPesoUti(carroConvoglio) {
            if (carroConvoglio.listUti && carroConvoglio.listUti.length > 0) {
                let pesoTot = 0;
                carroConvoglio.listUti.forEach(el => {
                    pesoTot += (el.visitaUti.pesoLordo !== null ? el.visitaUti.pesoLordo : 0);
                });
                return pesoTot;
            }
            return 0;
        },
        getConvoglio(carroConvoglio) {
            if (carroConvoglio.visitaCarro && carroConvoglio.visitaCarro.carroConvoglioList && carroConvoglio.visitaCarro.carroConvoglioList.length > 0) {
                return carroConvoglio.visitaCarro.carroConvoglioList[0].convoglio;
            }
            return undefined;
        },
        getBinario(carroConvoglio) {
            var convoglio = this.getConvoglio(carroConvoglio);
            if (convoglio) {
                return convoglio.binario;
            }
            return undefined;
        },
        getParcoBinari(carroConvoglio) {
            var binario = this.getBinario(carroConvoglio);
            if (binario) {
                return binario.parcoBinari;
            }
            return undefined;
        },
        getCarroPosizioneBinario(carroConvoglio) {
            if (isNaN(carroConvoglio.posizioneBinario)) return "N/A";
            return Number.parseFloat(carroConvoglio.posizioneBinario).toFixed(1);
        },
        getUtiCode(utiCarro) {
            if (utiCarro.visitaUti.uti) {
                return utiCarro.visitaUti.uti.codice;
            } else if (utiCarro.visitaUti.tipoUti) {
                return utiCarro.visitaUti.tipoUti.codice;
            }
            return '';
        },
        getUtiPackagesNumber(utiCarro) {
            if (utiCarro.visitaUti.numeroColli) {
                return utiCarro.visitaUti.numeroColli;
            }
            return 0;
        },
        getServiceOut(utiCarro) {
            return utiCarro.visitaUti.serviceOut;
        },
        getCarrierOut(utiCarro) {
            return utiCarro.visitaUti.carrierOut;
        },
        getUtiType(utiCarro) {
            if (utiCarro.visitaUti.tipoUti) {
                return utiCarro.visitaUti.tipoUti.codice;
            } else if (utiCarro.visitaUti.uti && utiCarro.visitaUti.uti.tipoUti) {
                return utiCarro.visitaUti.uti.tipoUti.codice;
            }
            return '';
        },
        getUtiWeight(utiCarro) {
            if (utiCarro?.visitaUti?.uti?.peso) {
                return utiCarro.visitaUti.uti.peso;
            } else if (utiCarro?.visitaUti?.tipoUti?.peso) {
                return utiCarro.visitaUti.tipoUti.peso;
            } else if (utiCarro?.visitaUti?.pesoLordo) {
                return utiCarro?.visitaUti?.pesoLordo;
            }
            return '';
        },
        getUtiLength(utiCarro) {
            if (utiCarro.visitaUti.uti) {
                return utiCarro.visitaUti.uti.lunghezza;
            } else if (utiCarro.visitaUti.tipoUti) {
                return utiCarro.visitaUti.tipoUti.lunghezza;
            }
            return '';
        },
        getUtiDangerousFlag(utiCarro) {
            if (utiCarro.visitaUti.uti) {
                return utiCarro.visitaUti.uti.pericoloso;
            } else if (utiCarro.visitaUti.tipoUti) {
                return utiCarro.visitaUti.tipoUti.pericoloso;
            }
            return false;
        },
        getUtiNumber(utiCarro) {
            return utiCarro.visitaUti.numeroColli;
        }
    },
    computed: {
        exportUtiStates() {
            return this._.filter(this.$config.rails.utiStates.export, st => st.label.toLowerCase() !== 'reset');
        },
        importUtiStates() {
            return this._.filter(this.$config.rails.utiStates.import, st => st.label.toLowerCase() !== 'reset');
        },
        infoConvoglio() {
            if (!this.convoglio) return '';

            let info = 'Provenienze (';
            const tripsIn = [];
            const tripsOut = [];
            this._.forIn(this.convoglio.listCarri, (carro) => {
                if (carro.viaggioIn && carro.viaggioIn.nodoArrivo && !tripsIn.find(v => carro.viaggioIn.nodoPartenza.codice === v)) {
                    info += tripsIn.length > 0 ? ', ' : '';
                    info += carro.viaggioIn.nodoPartenza.codice;
                    tripsIn.push(carro.viaggioIn.nodoPartenza.codice);
                }
            });
            info += ') Destinazioni (';
            this._.forIn(this.convoglio.listCarri, (carro) => {
                if (carro.viaggioOut && carro.viaggioOut.nodoArrivo && !tripsOut.find(v => carro.viaggioOut.nodoArrivo.codice === v)) {
                    info += tripsOut.length > 0 ? ', ' : '';
                    info += carro.viaggioOut.nodoArrivo.codice;
                    tripsOut.push(carro.viaggioOut.nodoArrivo.codice);
                }
            });
            return info += ')';
        }
    }
};