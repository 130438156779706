<template>
  <v-container class="info-panel">
    <v-row no-gutters>
      <v-col>
        <span
          ><b>{{ event.name }}</b></span
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span
          >stato: <b>{{ event.status }}</b></span
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span
          >ETA: <b>{{ event.convoglio.eta | formatDateTime }}</b></span
        >
      </v-col>
      <v-col>
        <span
          >ATA: <b>{{ event.convoglio.ata | formatDateTime }}</b></span
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span
          >ETD: <b>{{ event.convoglio.etd | formatDateTime }}</b></span
        >
      </v-col>
      <v-col>
        <span
          >ATD: <b>{{ event.convoglio.atd | formatDateTime }}</b></span
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span
          >t. occ.: <b>{{ event.inizio | formatDateTimeNoYear }} - {{ event.fine | formatDateTimeNoYear }}</b></span
        >
      </v-col>
      <v-col>
        <span
          >t. lav.: <b>{{ event.convoglio.tempoLavorazione }}</b></span
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span>viaggi in (prov.):</span>
      </v-col>
      <v-col v-for="viaggio in viaggiArrivo" :key="viaggio.id">
        <span
          ><b>{{ viaggio.nome }} - {{ viaggio.nodoPartenza.codice }}</b></span
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <span>viaggi out (dest.):</span>
      </v-col>
      <v-col v-for="viaggio in viaggiPartenza" :key="viaggio.id">
        <span
          ><b>{{ viaggio.nome }} - {{ viaggio.nodoArrivo.codice }}</b></span
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import railsMixins from "../rails/mixins/railsMixins";

export default {
  name: "convoyInfoPanel",
  mixins: [railsMixins],
  data() {
    return {
      viaggiArrivo: [],
      viaggiPartenza: [],
    };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    if (this.event && this.event.convoglio && this.event.convoglio.listCarri && this.event.convoglio.listCarri.length > 0) {
      this.event.convoglio.listCarri.forEach((carro) => {
        if (carro.viaggioIn && !this.viaggiArrivo.find((v) => v.id === carro.viaggioIn.id)) {
          this.viaggiArrivo.push(carro.viaggioIn);
        }
        if (carro.viaggioOut && !this.viaggiPartenza.find((v) => v.id === carro.viaggioOut.id)) {
          this.viaggiPartenza.push(carro.viaggioOut);
        }
      });
    }
  },
};
</script>

<style scoped>
.info-panel {
  padding: 0px !important;
}
</style>
